import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "build-a-better-web-together",
      "style": {
        "position": "relative"
      }
    }}>{`Build A Better Web, Together`}<a parentName="h1" {...{
        "href": "#build-a-better-web-together",
        "aria-label": "build a better web together permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`The intention of the Learn Track is to present "ever-green" content: `}<em parentName="p">{`ways of thinking`}</em>{` that will be relevant and useful for at least the next decade. Of course, this is not possible when looking at specific tools and techniques - as we must do in the Build Track - which change every few months as technology continues to advance along an exponential curve.`}</p>
    <p>{`Therefore, the Build Track is organised into more ephemeral "build guilds", which will likely change or be updated every few blocks. The intention is exactly the same: we wish to impart the necessary skills you need to participate meaningfully in web3. The way we do so differs: rather than a largely static syllabus, we invite the best builders, creators, artists, inventors, hackers, tinkerers and gift-givers to present shorter courses in an area of their speciality.`}</p>
    <p>{`Over time, we hope to gather here actual web3 legos: really useful building blocks of all shapes and sizes, scattered across multiple blocks, which you can put together yourself into something unique and beautiful.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/kernel_learn.png",
        "alt": "Kernel Build Track"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      